<h2 mat-dialog-title>Enter Master Password</h2>
<form (ngSubmit)="enterMasterPassword()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="password" name="password" type="password" required>
    </mat-form-field>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-flat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-flat-button type="submit" color="primary">Submit</button>
  </div>
</form>
