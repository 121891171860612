<h1 mat-dialog-title>Connection error</h1>
<mat-dialog-content class="mat-body">
    <p>Connection does not exist or credentials are incorrect.</p>
    <strong class="connection-error-message">Error: {{data.errorMessage}}</strong>

    <div *ngIf="data.provider" class="docs-notification">
        Need help with {{providerNames[data.provider]}} integration?
        <a [href]="providerDocsLink[data.provider]"
            target="_blank" class="docs-link">
            Open docs
        </a>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="connection-error__actions" align="end">
    <button *ngIf="data.dbCreds && data.dbCreds.id; else addButton"
        mat-button mat-dialog-close
        (click)="editConnection()">
    Save anyway
    </button>
    <ng-template #addButton>
        <button mat-button mat-dialog-close color="primary"
            (click)="createConnection()">
            Add anyway
        </button>
    </ng-template>

    <div class="main-actions">
        <button mat-button mat-dialog-close="change" color="accent" >
            Keep editing
        </button>
        <button *ngIf="isSaas" mat-flat-button color="accent" (click)="openIntercome()" cdkFocusInitial>Chat with support</button>
        <a *ngIf="!isSaas" mat-flat-button color="accent"
            href="https://github.com/rocket-admin/rocketadmin/issues" target="_blank" cdkFocusInitial>
            Report a bug
        </a>
    </div>
</mat-dialog-actions>