import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-placeholder-table-widgets',
  templateUrl: './placeholder-table-widgets.component.html',
  styleUrls: ['./placeholder-table-widgets.component.css'],
  imports: [MatIconModule]
})
export class PlaceholderTableWidgetsComponent {

}
