<mat-sidenav-container class="main-menu-container" autosize>
    <mat-sidenav #drawer
        fixedInViewport="true"
        mode="over"
        class="main-menu-sidenav"
        >
        <mat-toolbar>Rocketadmin</mat-toolbar>
        <mat-nav-list *ngIf="userLoggedIn === true">
            <a mat-list-item routerLink="/connections-list"
                routerLinkActive="nav-bar__button_active"
                aria-label="List of connections">
                <mat-icon matListItemIcon class="connection-navigation__icon">
                    list
                </mat-icon>
                <div matListItemTitle>Connections</div>
            </a>
            <mat-nav-list *ngIf="connectionID && visibleTabs" class="connection-navigation">
                <a mat-list-item *ngFor="let tab of visibleTabs" attr.data-testid="{{tab}}-header-link"
                    routerLink="{{tab}}/{{connectionID}}"
                    routerLinkActive="nav-bar__button_active">
                    {{navigationTabs[tab].caption}}
                </a>
            </mat-nav-list>
            <a mat-list-item routerLink="/user-settings" class="connection-navigation__item_user" data-testid="account-link-account-menu">
                <mat-icon matListItemIcon class="connection-navigation__icon connection-navigation__icon_account"
                    matBadge="1" [matBadgeHidden]="currentUser.isActive"
                    matBadgeColor="accent" matBadgeSize="small">
                    face
                </mat-icon>
                <div matListItemTitle>Account</div>
                <div matListItemLine>{{currentUser.email}}</div>
            </a>
            <a mat-list-item routerLink="/company" data-testid="company-link-account-menu">
                <mat-icon matListItemIcon class="connection-navigation__icon">
                    apartment
                </mat-icon>
                <div matListItemTitle>Company</div>
            </a>
            <a mat-list-item href="https://docs.rocketadmin.com/" target="_blank">
                <mat-icon matListItemIcon class="connection-navigation__icon" fontSet="material-icons-outlined">help_outlined</mat-icon>
                <div matListItemTitle>Help center</div>
            </a>
            <mat-list-item (click)="logOut()" data-testid="logout-button-account-menu">
                <mat-icon matListItemIcon class="connection-navigation__icon">exit_to_app</mat-icon>
                <div matListItemTitle>Log out</div>
            </mat-list-item>
        </mat-nav-list>
        <a mat-flat-button color="accent" *ngIf="isSaas && currentUser && currentUser.role === 'ADMIN'" class="connection-navigation__upgrade-button" routerLink="/upgrade"
            routerLinkActive="nav-bar__button_active">
            Upgrade
        </a>
    </mat-sidenav>

    <mat-sidenav-content class="main-menu-content">
        <mat-toolbar color="primary" class="nav-bar"
            [ngClass]="{'nav-bar_home': !connectionID, 'nav-bar_connection': connectionID,
                'nav-bar_exterior': !userLoggedIn, 'nav-bar_interior': userLoggedIn}">
            <a routerLink="/dashboard/{{connectionID}}" *ngIf="userLoggedIn && logo && name" class="logo">
                <img [src]="logo || '../assets/rocketadmin_logo_white.svg'" alt="Logo" class="logo__image">
                <span data-id="connection-custom-name">{{name}}</span>
            </a>

            <a routerLink="/dashboard/{{connectionID}}" *ngIf="userLoggedIn && logo && !name" class="logo">
                <img [src]="logo || '../assets/rocketadmin_logo_white.svg'" alt="Logo" class="logo__image">
            </a>

            <a routerLink="/dashboard/{{connectionID}}" *ngIf="userLoggedIn && !logo && name" class="logo">
                <img src="../assets/rocketadmin_logo_white-short.svg" alt="Logo" class="logo__image">
                <span data-id="connection-custom-name">{{name}}</span>
            </a>

            <a routerLink="/connections-list" *ngIf="userLoggedIn && !logo && !name" class="logo">
                <img *ngIf="!logo && !name" src="../assets/rocketadmin_logo_white.svg" alt="Rocketadmin logo" class="logo__image">
            </a>

            <a *ngIf="!userLoggedIn" href="https://rocketadmin.com/" class="logo">
                <picture>
                    <source media="(max-width: 767px)" srcset="../assets/rocketadmin_logo_white-short.svg">
                    <img src="../assets/rocketadmin_logo_white.svg" alt="Rocketadmin logo" class="logo__image">
                </picture>
            </a>

            <div *ngIf="connectionID" class="menu">
                <a mat-button data-testid="connections-list-header-link"
                    routerLink="/connections-list"
                    routerLinkActive="nav-bar__button_active"
                    class="nav-bar__button">
                    Connections
                </a>
                <mat-icon class="nav-bar__button">chevron_right</mat-icon>
                <a mat-button *ngFor="let tab of visibleTabs" attr.data-testid="{{tab}}-header-link"
                    routerLink="{{tab}}/{{connectionID}}"
                    routerLinkActive="nav-bar__button_active"
                    class="nav-bar__button">
                    {{navigationTabs[tab].caption}}
                </a>
            </div>

            <button mat-icon-button *ngIf="userLoggedIn === true" color="primary" class="menu-button" (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>

            <div *ngIf="userLoggedIn === true" class="actions actions_auth">
                <a routerLink="/upgrade" *ngIf="isSaas && currentUser && currentUser.role === 'ADMIN'"
                    mat-flat-button color="accent"
                    data-testid="upgrade-header-link"
                    routerLinkActive="mat-accent" class="nav-bar__upgrade-button">
                    Upgrade
                </a>

                <button mat-icon-button type="button" data-testid="account-menu-anchor-header-button"
                    matBadge="1" [matBadgeHidden]="currentUser.isActive"
                    matBadgeColor="accent" matBadgeSize="small"
                    [matMenuTriggerFor]="accountMenu"
                    matTooltip="{{currentUser.email}}"
                    class="nav-bar__account-button">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <mat-menu #accountMenu="matMenu" data-testid="account-menu-dropdown">
                    <a mat-menu-item routerLink="/user-settings" data-testid="account-link-account-menu">
                        <mat-icon class="nav-menu__list-link-icon"
                            matBadge="1" [matBadgeHidden]="currentUser.isActive"
                            matBadgeColor="accent" matBadgeSize="small">
                            face
                        </mat-icon>
                        <span>Account</span>
                        <br/>
                        <span class="user-email">{{currentUser.email}}</span>
                    </a>
                    <a mat-menu-item routerLink="/company" data-testid="company-link-account-menu">
                        <mat-icon class="nav-menu__list-link-icon">
                            apartment
                        </mat-icon>
                        <span>Company</span>
                    </a>
                    <a mat-menu-item href="https://docs.rocketadmin.com/" target="_blank">
                        <mat-icon fontSet="material-icons-outlined">help_outlined</mat-icon>
                        <span>Help center</span>
                    </a>
                    <button mat-menu-item (click)="logOut()" data-testid="logout-button-account-menu">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Log out</span>
                    </button>
                </mat-menu>
            </div>

            <div *ngIf="userLoggedIn === null" class="actions">
                <ng-container *ngIf="page === '/login'">
                    <a mat-stroked-button routerLink="/login"
                        data-testid="login-header-link"
                        class="action">
                        Login
                    </a>
                    <a mat-flat-button color="accent" routerLink="/registration"
                        data-testid="registration-header-link"
                        class="action">
                        Sign up
                    </a>
                </ng-container>
                <ng-container *ngIf="page === '/registration'">
                    <a mat-flat-button color="accent" routerLink="/login"
                        data-testid="login-header-link"
                        class="action">
                        Login
                    </a>
                    <a mat-stroked-button routerLink="/registration"
                        data-testid="registration-header-link"
                        class="action">
                        Sign up
                    </a>
                </ng-container>

            </div>
            <!-- <ng-template #nonAuthBlock>

            </ng-template> -->
        </mat-toolbar>

        <mat-tab-nav-panel #tabPanel class="tab-content-wrapper">
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </mat-tab-nav-panel>

        <div *ngIf="!authBarTheme" class="footer">
            <span class="footer__text">&copy; 2024 Rocketadmin</span>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

