<div class="wrapper">
    <header>
        <h1>Audit</h1>
        <div class="filters">
            <mat-form-field *ngIf="tablesList && !isServerError && !noTablesError" appearance="outline">
                <mat-label>Tables</mat-label>
                <mat-select
                    angulartics2On="click"
                    angularticsAction="Audit: table is selected"
                    [(ngModel)]="tableName"
                    (ngModelChange)="loadLogsPage()">
                    <mat-option value="showAll">Show all</mat-option>
                    <mat-option *ngFor="let tableItem of tablesList" [value]="tableItem.table">
                        <div class="table-name">
                            <span class="table-name__title">{{tableItem.display_name || tableItem.normalizedTableName}}</span>
                            <span class="table-name__line"> ({{tableItem.table}})</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="usersList && !isServerError" appearance="outline">
                <mat-label>Users</mat-label>
                <mat-select
                    angulartics2On="click"
                    angularticsAction="Audit: user is selected"
                    [(ngModel)]="userEmail" (ngModelChange)="loadLogsPage()">
                    <mat-option value="showAll">Show all</mat-option>
                    <mat-option *ngFor="let user of usersList" [value]="user.email">{{user.email}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </header>

    <app-banner *ngIf="noTablesError" type="info" class="audit-banner">
        <h3 class='mat-subheading-2'>Rocketadmin can not find any tables</h3>
        <p class="mat-body-1">
            There are no tables Rocketadmin can track actions on.
            Please grant Rocketadmin access to required tables or create new table.
            You can create a table with SQL editor.
        </p>
    </app-banner>

    <app-banner *ngIf="isServerError" type="error" class="audit-banner">
        <div *ngIf="serverError.details; else stringError" class="mat-body-1">
            <strong>{{serverError.abstract}}</strong>
            <p class="mat-body-1 error-details">{{serverError.details}}</p>
        </div>
        <ng-template #stringError>
            <p class="mat-body-1">{{serverError.abstract}}</p>
        </ng-template>
        <div class="error-actions">
            <a mat-stroked-button routerLink="/edit-db/{{connectionID}}">Connection settings</a>
            <button *ngIf="isSaas" mat-flat-button color="warn" (click)="openIntercome()">Chat with support</button>
            <a *ngIf="!isSaas" mat-flat-button color="warn"
                href="https://github.com/rocket-admin/rocketadmin/issues" target="_blank">
                Report a bug
            </a>
        </div>
    </app-banner>

    <div *ngIf="dataSource && dataSource.loading$ | async" class="skeleton mat-elevation-z4">
        <app-placeholder-table-data></app-placeholder-table-data>
    </div>

    <div *ngIf="!noTablesError && !isServerError"
        class="mat-elevation-z4 table-wrapper"
        [ngClass]="{hidden: dataSource.loading$ | async}">
        <table mat-table [dataSource]="dataSource" NgMatTableQueryReflector>
            <ng-container [matColumnDef]="column" *ngFor="let column of dataColumns">
                <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="table-cell-content" >
                        {{element[column] || '—'}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Details">
                <th mat-header-cell *matHeaderCellDef> Details </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button color="primary"
                        angulartics2On="click"
                        angularticsAction="Audit: view changes is clicked"
                        (click)="openInfoLogDialog(element)">
                        View changes
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>

        <mat-paginator
            [pageSize]="30"
            [pageSizeOptions]="[10, 30, 100, 300]"
            [showFirstLastButtons]="true">
        </mat-paginator>
    </div>

</div>