<app-alert></app-alert>
<app-placeholder-table-settings *ngIf="loading"></app-placeholder-table-settings>

<div *ngIf="tableSettings !== null && currentConnection" class="settings-page">
    <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title || currentConnection.database)" class="row-breadcrumbs"></app-breadcrumbs>
    <form #tableSettingsForm="ngForm" class="settings-form" (ngSubmit)="updateSettings()">
        <div class="settings-fields">
            <h2 class="mat-heading-2 settings-fields__heading">General</h2>

            <div class="settings-table-display">
                <mat-form-field appearance="outline" class="settings-table-display__title">
                    <mat-label>Table display name</mat-label>
                    <input matInput [(ngModel)]="tableSettings.display_name" name="display_name" #displayName="ngModel">
                    <!-- <mat-error *ngIf="title.errors?.required && (title.invalid && title.touched)">Title should not be empty.</mat-error> -->
                    <mat-hint>Enter table name to be displayed in the interface.</mat-hint>
                </mat-form-field>
                <app-icon-picker class="icon-picker" [resetButtonShown]="true"
                    [icon]="tableSettings.icon"
                    [defaultIcons]="defaultIcons"
                    tooltip="Choose an icon"
                    (onFieldChange)="updateIcon($event)">
                </app-icon-picker>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Sensitive fields</mat-label>
                <mat-select multiple
                    name="columns_sensitive"
                    [(ngModel)]="tableSettings.sensitive_fields">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Define sensitive fields to hide them from Audit logs.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Identity field</mat-label>
                <mat-select
                    name="identity_column"
                    [(ngModel)]="tableSettings.identity_column">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select a column you want to display as a foreign key field.</mat-hint>
            </mat-form-field>

            <mat-slide-toggle name="allow_export"
                color="primary"
                [(ngModel)]="tableSettings.allow_csv_export">
                Allow export
            </mat-slide-toggle>

            <mat-slide-toggle name="allow_import"
                color="primary"
                [(ngModel)]="tableSettings.allow_csv_import">
                Allow import
            </mat-slide-toggle>

            <h2 class="mat-heading-2 settings-fields__heading">Table view</h2>
            <mat-form-field appearance="outline">
                <mat-label>Columns visible by default</mat-label>
                <mat-select multiple
                    name="columns_view"
                    [(ngModel)]="tableSettings.columns_view">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select the columns you always want to see when you open this table.</mat-hint>
            </mat-form-field>

            <div class="order-settings">
                <div class="order-settings__panel">
                    <span class="order__title">
                        Columns order
                    </span>
                    <mat-expansion-panel class="order-settings__expansion-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div>
                                    <span *ngIf="tableSettings.list_fields.length === 0; else orderedList">Standard order</span>
                                    <ng-template #orderedList>
                                        <span *ngFor="let field of tableSettings.list_fields; let i = index">
                                            {{field}}{{ i === tableSettings.list_fields.length - 1 ? '' : ',' }}
                                        </span>
                                    </ng-template>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
                            <div class="order-item" *ngFor="let field of listFieldsOrder" cdkDrag>{{field}}</div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <button mat-icon-button type="button"
                    class="order-settings__reset-button"
                    matTooltip="Reset"
                    [disabled]="tableSettings.list_fields.length === 0"
                    (click)="resetColumnsOrder()">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Fields to search</mat-label>
                <mat-select multiple
                    name="search_fields"
                    [(ngModel)]="tableSettings.search_fields">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select the columns you often use as filters.</mat-hint>
            </mat-form-field>

            <div class="form-group-ordering">
                <mat-form-field appearance="outline" class="form-group-ordering__ordering-by">
                    <mat-label>Ordering by field</mat-label>
                    <mat-select name="ordering_field" [(ngModel)]="tableSettings.ordering_field">
                        <mat-option value="">None</mat-option>
                        <mat-option *ngFor="let field of fields" [value]="field">
                            {{field}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-radio-group aria-label="Order"
                    class="form-group-ordering__order-options"
                    name="ordering"
                    [(ngModel)]="tableSettings.ordering">
                    <mat-radio-button value="ASC" checked>Ascending</mat-radio-button>
                    <mat-radio-button value="DESC">Descending</mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Sort fields by</mat-label>
                <mat-select multiple
                    name="sortable_by"
                    [(ngModel)]="tableSettings.sortable_by">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
            </mat-form-field>

            <h2 class="mat-heading-2" style="margin-bottom: -4px;">Record view</h2>
            <mat-form-field appearance="outline">
                <mat-label>Foreign key search fields</mat-label>
                <mat-select multiple
                    name="autocomplete_columns"
                    [(ngModel)]="tableSettings.autocomplete_columns">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select the columns you want to search by when you look for the record as a foreign key.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Fields to exclude</mat-label>
                <mat-select multiple
                    name="excluded_fields"
                    [(ngModel)]="tableSettings.excluded_fields">
                    <mat-option *ngFor="let field of fields_to_exclude" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select the columns you want to hide and protect from editing.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Read-only fields</mat-label>
                <mat-select multiple
                    name="readonly_fields"
                    [(ngModel)]="tableSettings.readonly_fields">
                    <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
                </mat-select>
                <mat-hint>Select the columns you want to display and protect from editing.</mat-hint>
            </mat-form-field>
        </div>

        <div class="actions">
            <button mat-stroked-button type="button" class="settings-form__back-button" (click)="goBack()">Back</button>

            <button mat-flat-button color="warn"
                type="button"
                [disabled]="submitting || !isSettingsExist"
                (click)="resetSettings(tableSettingsForm)">
                Reset
            </button>
            <button mat-flat-button color="primary"
                type="submit"
                class="settings-form__save-button"
                [disabled]="(submitting || tableSettingsForm.form.invalid || tableSettingsForm.form.pristine) && !orderChanged && !iconChanged">
                Save
            </button>
        </div>
    </form>

</div>