<mat-form-field appearance="outline" class="connectForm__hostname credentials-fieldset__1-3-columns">
    <mat-label>Hostname</mat-label>
    <input matInput name="hostname" #hostname="ngModel"
        data-testid="connection-hostname-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: hostname is edited"
        required hostnameValidator
        [readonly]="readonly"
        [disabled]="submitting"
        [(ngModel)]="connection.host">
    <mat-hint>
        E.g. <strong><code>my-test-db.cvfuxe8nltiq.us-east-2.rds.amazonaws.com</code></strong>.
        Connections from internal IPs (e.g. localhost) are not supported.
    </mat-hint>

    <mat-error *ngIf="hostname.errors?.isLocalhost && hostname.invalid">
        To connect a database on internal IP use <strong>Pinggy</strong>
        (<a [href]="tunnelingServiceLink" target="_blank" class="credentials-fieldset__hint-link">how-to</a>)
        or <button type="button" (click)="switchToAgent.emit()" class="credentials-fieldset__hint-button">click here</button> for agent connection.
    </mat-error>
    <mat-error *ngIf="hostname.errors?.isInvalidHostname && hostname.invalid">Hostname is invalid.</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="connectForm__port">
    <mat-label>Port</mat-label>
    <input matInput type="number" name="port" #port="ngModel"
        data-testid="connection-port-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: port is edited"
        required
        [readonly]="readonly"
        [disabled]="submitting"
        [(ngModel)]="connection.port">
    <mat-error *ngIf="port.errors?.required && (port.invalid && port.touched)">Port should not be empty.</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="credentials-fieldset__1-2-columns">
    <mat-label>Username</mat-label>
    <input matInput name="username" #username="ngModel"
        data-testid="connection-username-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: username is edited"
        required
        [readonly]="readonly"
        [disabled]="submitting"
        [(ngModel)]="connection.username">
    <mat-error *ngIf="username.errors?.required && (username.invalid && username.touched)">Username should not be empty.</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="credentials-fieldset__3-4-columns">
    <mat-label>Password</mat-label>
    <input type="password" matInput name="password" #password="ngModel"
        data-testid="connection-password-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: password is edited"
        [required]="!connection.id || hostname.touched || port.touched"
        [readonly]="readonly"
        [disabled]="submitting"
        [(ngModel)]="connection.password">
    <mat-hint *ngIf="connection.id && (hostname.pristine && port.pristine)">To keep password the same keep this field blank.</mat-hint>
    <mat-hint *ngIf="connection.id && (hostname.dirty || port.dirty)">Password needed due to hostname/port change.</mat-hint>
    <!-- <mat-error *ngIf="email.errors.required && (email.invalid && email.touched)">Email should not be empty.</mat-error> -->
</mat-form-field>

<mat-form-field appearance="outline" class="credentials-fieldset__1-4-columns">
    <mat-label>Database Name</mat-label>
    <input matInput name="database" #database="ngModel"
        data-testid="connection-database-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: database name is edited"
        required
        [readonly]="readonly"
        [disabled]="submitting"
        [(ngModel)]="connection.database">
    <mat-error *ngIf="database.errors?.required && (database.invalid && database.touched)">Name should not be empty.</mat-error>
</mat-form-field>

<mat-expansion-panel class="credentials-fieldset__1-4-columns">
    <mat-expansion-panel-header data-testid="connection-advanced-settings-expansion-panel-header">
        <mat-panel-title>
            Advanced settings
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="advanced-settings">
        <app-master-encryption-password
            class="advanced-settings__fullLine"
            [masterKey]="masterKey"
            [disabled]="accessLevel === 'readonly' || submitting || connection?.isTestConnection"
            (onMasterKeyChange)="handleMasterKeyChange($event)">
        </app-master-encryption-password>

        <mat-checkbox class="checkbox-line advanced-settings__fullLine" name="ssh" #ssh="ngModel"
            data-testid="connection-ssh-checkbox"
            labelPosition="after"
            angulartics2On="click"
            angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSH is switched"
            [angularticsProperties]="{'enable': connection.ssh}"
            [disabled]="submitting || connection.isTestConnection"
            [(ngModel)]="connection.ssh">
            Use SSH tunnel
        </mat-checkbox>

        <mat-form-field *ngIf="connection.ssh" appearance="outline" class="advanced-settings__fullLine">
            <mat-label>Private SSH key</mat-label>
            <textarea matInput resizeToFitContent rows="8" name="privateSSHKey" #privateSSHKey="ngModel"
                data-testid="connection-ssh-key-textarea"
                angulartics2On="change"
                angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSH key is edited"
                [required]="connection.ssh && !connection.id" [readonly]="accessLevel === 'readonly' && connection.id"
                [disabled]="submitting"
                [(ngModel)]="connection.privateSSHKey"
            ></textarea>
            <mat-error *ngIf="privateSSHKey.errors?.required && (privateSSHKey.invalid && privateSSHKey.touched)">Private SSH key should not be empty.</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="connection.ssh" appearance="outline">
            <mat-label>SSH host</mat-label>
            <input matInput name="sshHost" #sshHost="ngModel"
                data-testid="connection-ssh-host-input"
                angulartics2On="change"
                angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSH host is edited"
                [required]="connection.ssh" [readonly]="accessLevel === 'readonly' && connection.id"
                [disabled]="submitting"
                [(ngModel)]="connection.sshHost">
            <mat-error *ngIf="sshHost.errors?.required && (sshHost.invalid && sshHost.touched)">SSH host should not be empty.</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="connection.ssh" appearance="outline">
            <mat-label>SSH port</mat-label>
            <input matInput type="number" name="sshPort" #sshPort="ngModel"
                data-testid="connection-ssh-port-input"
                angulartics2On="change"
                angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSH port is edited"
                [required]="connection.ssh" [readonly]="accessLevel === 'readonly' && connection.id"
                [disabled]="submitting"
                [(ngModel)]="connection.sshPort">
            <mat-error *ngIf="sshPort.errors?.required && (sshPort.invalid && sshPort.touched)">SSH port should not be empty.</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="connection.ssh" appearance="outline">
            <mat-label>SSH username</mat-label>
            <input matInput name="sshUsername" #sshUsername="ngModel"
                data-testid="connection-ssh-username-input"
                angulartics2On="change"
                angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSH username is edited"
                [required]="connection.ssh" [readonly]="accessLevel === 'readonly' && connection.id"
                [disabled]="submitting"
                [(ngModel)]="connection.sshUsername">
            <mat-error *ngIf="sshUsername.errors?.required && (sshUsername.invalid && sshUsername.touched)">SSH username should not be empty.</mat-error>
        </mat-form-field>

        <mat-checkbox class="checkbox-line advanced-settings__fullLine" name="ssl" #ssh="ngModel"
            labelPosition="after"
            data-testid="connection-ssl-checkbox"
            angulartics2On="click"
            angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSL is switched"
            [angularticsProperties]="{'enable': connection.ssl}"
            [disabled]="submitting || connection.isTestConnection"
            [(ngModel)]="connection.ssl">
            Check SSL certificate
        </mat-checkbox>

        <mat-form-field *ngIf="connection.ssl" appearance="outline" class="advanced-settings__fullLine">
            <mat-label>SSL certificate</mat-label>
            <textarea matInput resizeToFitContent rows="8" name="sslCert" #sslCert="ngModel"
                data-testid="connection-ssl-certificate-textarea"
                angulartics2On="change"
                angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: SSL certificate is edited"
                [required]="connection.ssl" [readonly]="accessLevel === 'readonly' && connection.id"
                [disabled]="submitting"
                [(ngModel)]="connection.cert"
            ></textarea>
            <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error>
        </mat-form-field>

        <mat-checkbox *ngIf="connection.type === 'mssql'" class="checkbox-line advanced-settings__fullLine" name="ssl" #ssh="ngModel"
            labelPosition="after"
            data-testid="connection-ssl-encryption-checkbox"
            angulartics2On="click"
            angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: azure encryption is switched"
            [angularticsProperties]="{'enable': connection.azure_encryption}"
            [disabled]="submitting"
            [(ngModel)]="connection.azure_encryption">
            Encryption
        </mat-checkbox>
    </div>
</mat-expansion-panel>