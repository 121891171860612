<div>
    <zapier-workflow
        [signUpEmail]="currentUser.email"
        [clientId]='"4MFV3OrjI8e8JkZPySqbFVbKtZUz0sV0H3XJnb6w"'
        [theme]='"auto"'
        [introCopyDisplay]='"show"'
        [guessZapDisplay]='"show"'
        [zapCreateFromScratchDisplay]='"hide"'
    ></zapier-workflow>
</div>
