<h1 mat-dialog-title>Confirm API key delete</h1>
<mat-dialog-content>
    <p class="mat-body">You are going to delete <strong>{{ data.title }}</strong> API key.</p>
    <br>
    <p class="mat-body">Please confirm.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close mat-dialog-close>Cancel</button>
  <button mat-flat-button color="warn" mat-dialog-close="delete"
    [disabled]="submitting"
    (click)="deleteAPIkey()">
      Delete
  </button>
</mat-dialog-actions>