<app-alert></app-alert>

<!--<div class="upgrade-box">
    <h1 class='mat-h1 upgrade-box__title'>Manage plans</h1>
    <stripe-pricing-table *ngIf="currentUser"
        [attr.client-reference-id]="currentUser.id"
        [attr.customer-email]="currentUser.email"
        pricing-table-id="prctbl_1LQzmPFtHdda1TsBHcj3EIvg"
        publishable-key="pk_live_51JM8FBFtHdda1TsBR7nieMFVFigZAUXbPhQTNvaSyLynIW1lbfzO6rfqqIUn0JAGJRq9mrwKwrVCsDDFOs84M7pE006xDqNgHk">
    </stripe-pricing-table>
</div>-->

<div class="page">
    <div class="plans">
        <div class="header">
            <div>

            </div>
            <ng-container *ngFor="let plan of plans">
                <div *ngIf="plan.key === 'title'; else planHeaderCard">
                    <h1 class="mat-h1">
                        Manage plans
                    </h1>
                </div>
                <ng-template #planHeaderCard>
                    <div class="plan-header mat-elevation-z2">
                        <div class="plan-header-name">
                            <span class="mat-h3">{{plan.name}} </span>
                            <mat-chip disabled *ngIf="plan.name === 'Team'" class="plan-badge">Recommended</mat-chip>
                        </div>
                        <div>
                            <span class="price">${{ plan.price }}</span><span class="per">/monthly</span>
                        </div>
                        <span class="per users">
                            <span *ngIf="plan.price !== 0">per each <strong class="users__value">10 users</strong></span>
                            <span *ngIf="plan.price === 0">up to <strong class="users__value">3 users</strong></span>
                        </span>
                        <button mat-flat-button color="accent"
                            *ngIf="plan.key === currentPlan.key; else activeButton"
                            disabled class="current">
                            Current
                        </button>
                        <ng-template #activeButton>
                            <button mat-flat-button color="accent" type="button"
                                *ngIf="plan.price > currentPlan.price && hasPaymentMethod"
                                [disabled]="submitting"
                                (click)="changePlan(plan.key)">
                                Upgrade
                            </button>
                            <button mat-button type="button"
                                *ngIf="plan.price < currentPlan.price && hasPaymentMethod"
                                [disabled]="submitting"
                                (click)="changePlan(plan.key)">
                                Downgrade
                            </button>

                            <a mat-flat-button color="accent"
                                *ngIf="plan.price > currentPlan.price && !hasPaymentMethod"
                                [disabled]="submitting"
                                routerLink="/upgrade/payment"
                                [queryParams]="{plan: plan.key}">
                                Upgrade
                            </a>
                            <a mat-button
                                *ngIf="plan.price < currentPlan.price && !hasPaymentMethod"
                                [disabled]="submitting"
                                routerLink="/upgrade/payment"
                                [queryParams]="{plan: plan.key}">
                                Downgrade
                            </a>
                        </ng-template>
                    </div>
                </ng-template>
            </ng-container>


        </div>

        <h2 class="mat-header-2">Databases</h2>

        <table mat-table [dataSource]="databases" class="plansTable mat-elevation-z2">
            <colgroup>
                <col style="width: 28%">
                <col style="width: 24%">
                <col style="width: 24%">
                <col style="width: 24%">
            </colgroup>
            <ng-container *ngFor="let plan of plans" [matColumnDef]="plan.key">
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'cell_centered': plan.key !== 'title', cell_current: plan.key === currentPlan.key}">
                    <span *ngIf="element[plan.key] === true; else nonBoolValue">✓</span>
                    <ng-template #nonBoolValue>{{element[plan.key]}}</ng-template>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <h2 class="mat-header-2">Users</h2>

        <table mat-table [dataSource]="users" class="plansTable mat-elevation-z2">
            <colgroup>
                <col style="width: 28%">
                <col style="width: 24%">
                <col style="width: 24%">
                <col style="width: 24%">
            </colgroup>
            <ng-container *ngFor="let plan of plans" [matColumnDef]="plan.key">
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'cell_centered': plan.key !== 'title', cell_current: plan.key === currentPlan.key}">
                    <span *ngIf="element[plan.key] === true; else nonBoolValue">✓</span>
                    <ng-template #nonBoolValue>{{element[plan.key]}}</ng-template>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <h2 class="mat-header-2">Features</h2>

        <table mat-table [dataSource]="features" class="plansTable mat-elevation-z2">
            <colgroup>
                <col style="width: 28%">
                <col style="width: 24%">
                <col style="width: 24%">
                <col style="width: 24%">
            </colgroup>
            <ng-container *ngFor="let plan of plans" [matColumnDef]="plan.key">
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'cell_centered': plan.key !== 'title', cell_current: plan.key === currentPlan.key}">
                    <span *ngIf="element[plan.key] === true; else nonBoolValue">✓</span>
                    <ng-template #nonBoolValue>{{element[plan.key]}}</ng-template>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>