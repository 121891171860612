<div class="wrapper">
    <div class="register-form-box">
        <app-alert></app-alert>
        <form class="register-form"
            #registrationForm="ngForm"
            (ngSubmit)="registerUser()">
            <h1 class="mat-headline-4 registrationTitle registrationTitle_desktop">
                Create an Account to
                <span class="registrationTitle__emphasis">Manage Your Database</span>
            </h1>

            <h1 class="mat-headline-4 registrationTitle registrationTitle_mobile">
                Create an Account on
                <span class="registrationTitle__emphasis">Rocketadmin</span>
            </h1>

            <div *ngIf="isSaas" id="google_registration_button"
                angulartics2On="click"
                angularticsAction="Reg: sign up with google">
            </div>

            <button *ngIf="isSaas" type="button" mat-stroked-button color="primary"
                class="register-form__github-button"
                angulartics2On="click"
                angularticsAction="Reg: sign up with github is clicked"
                (click)="registerWithGithub()">
                <mat-icon class="register-form__github-icon" svgIcon="github"></mat-icon>
                <span class="register-form__github-caption">Continue with GitHub</span>
            </button>

            <div *ngIf="isSaas" class="divider"><span class="divider__label">or</span></div>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" #email="ngModel" required emailValidator
                    angulartics2On="change"
                    angularticsAction="Reg: email is changed"
                    [(ngModel)]="user.email">
                <mat-error *ngIf="email.errors?.isInvalidEmail">Invalid email format.</mat-error>
            </mat-form-field>

            <app-user-password label="Password" [value]="user.password"
                class="password-field"
                (onFieldChange)="updatePasswordField($event)">
            </app-user-password>

            <button type="submit" mat-flat-button color="accent" class="submit-button"
                angulartics2On="click"
                angularticsAction="Reg: sign up is clicked"
                [disabled]="submitting || registrationForm.form.invalid || registrationForm.form.pristine">
                {{ submitting ? 'Submitting' : 'Create account'}}
            </button>
            <p class="mat-caption agreement">
                By creating this account you agree to Rocketadmin's <br/>
                <a href="https://rocketadmin.com/privacy" class="link" target="_blanck">Privacy Policy</a>
                and
                <a href="https://rocketadmin.com/terms" class="link">Terms and Conditions</a>.
            </p>
        </form>
    </div>
    <div class="register-image-box">
        <img src="../assets/main-table-reg-image.svg" class="register-image" alt="">
    </div>
</div>
