<mat-form-field appearance="outline" class="connectForm__hostname credentials-fieldset__1-4-columns">
    <mat-label>Hostname</mat-label>
    <input matInput name="hostname" #hostname="ngModel"
        data-testid="connection-hostname-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: hostname is edited"
        required hostnameValidator="dynamodb"
        [readonly]="(accessLevel === 'readonly' || connection.isTestConnection) && connection.id"
        [disabled]="submitting"
        [(ngModel)]="connection.host">
    <mat-hint>
        <span>
            E.g. <strong><code>https://dynamodb.us-east-2.amazonaws.com</code></strong>.
            Select the endpoint matching your database region from the
                <a href="https://docs.aws.amazon.com/general/latest/gr/ddb.html#ddb_region" target="_blank" class="credentials-fieldset__hint-link">
                    AWS DynamoDB endpoints list
                </a>.
        </span>
    </mat-hint>

    <mat-error *ngIf="hostname.errors?.isLocalhost && hostname.invalid">
        To connect a database on internal IP use <strong>Pinggy</strong>
        (<a [href]="tunnelingServiceLink" target="_blank" class="connectForm__agent-connection-button">how-to</a>)
        or <button type="button" (click)="switchToAgent.emit()" class="connectForm__agent-connection-button">click here</button> for agent connection.
    </mat-error>
    <mat-error *ngIf="hostname.errors?.isInvalidHostname && hostname.invalid">Hostname is invalid.</mat-error>
    <mat-error *ngIf="hostname.errors?.missingHttps && hostname.invalid">Hostname must start with "https://".</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="credentials-fieldset__1-2-columns">
    <mat-label>Username</mat-label>
    <input matInput name="username" #username="ngModel"
        data-testid="connection-username-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: username is edited"
        required
        [readonly]="(accessLevel === 'readonly' || connection.isTestConnection) && connection.id"
        [disabled]="submitting"
        [(ngModel)]="connection.username">
    <mat-error *ngIf="username.errors?.required && (username.invalid && username.touched)">Username should not be empty.</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="credentials-fieldset__3-4-columns">
    <mat-label>Password</mat-label>
    <input type="password" matInput name="password" #password="ngModel"
        data-testid="connection-password-input"
        angulartics2On="change"
        angularticsAction="Connection creds {{ connection.id ? 'edit' : 'add' }}: password is edited"
        [required]="!connection.id || hostname.touched"
        [readonly]="(accessLevel === 'readonly' || connection.isTestConnection) && connection.id"
        [disabled]="submitting"
        [(ngModel)]="connection.password">
    <mat-hint *ngIf="connection.id && hostname.pristine">To keep password the same keep this field blank.</mat-hint>
    <mat-hint *ngIf="connection.id && hostname.dirty">Password needed due to hostname/port change.</mat-hint>
</mat-form-field>

<mat-expansion-panel class="credentials-fieldset__1-4-columns">
    <mat-expansion-panel-header data-testid="connection-advanced-settings-expansion-panel-header">
        <mat-panel-title>
            Advanced settings
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="advanced-settings">
        <div class="encription-setting advanced-settings__fullLine">
            <app-master-encryption-password class="advanced-settings__fullLine"
                [masterKey]="masterKey"
                [disabled]="accessLevel === 'readonly' || submitting || connection.isTestConnection"
                (onMasterKeyChange)="handleMasterKeyChange($event)">
            </app-master-encryption-password>
        </div>
    </div>
</mat-expansion-panel>