<div class="encription-setting">
    <mat-slide-toggle name="encryption"
        data-testid="connection-master-encryption-toggle"
        angulartics2On="click"
        angularticsAction="Connection creds: client-side encryption is switched"
        [angularticsProperties]="{'enable': isMasterKeyTurnedOn}"
        [disabled]="disabled"
        [(ngModel)]="isMasterKeyTurnedOn"
        (ngModelChange)="generatePassword($event)">
        Use client-side encryption
    </mat-slide-toggle>
    <div *ngIf="isMasterKeyTurnedOn && masterKey" class="encription-password">
        <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Master password</mat-label>
            <input matInput data-testid="connection-master-password-input"
                name="masterPassword" #masterPassword="ngModel"
                readonly
                [(ngModel)]="masterKey">
            <mat-hint>Rocketadmin does not store the key.
                Please save this password on your computer in protected place,
                we recommend using password managers.
                Share this password with all users of the connection.
            </mat-hint>
        </mat-form-field>
        <button type="button" data-testid="connection-master-password-copy-button"
            mat-icon-button
            class="encription-password__copy-button"
            matTooltip="Copy master password"
            [cdkCopyToClipboard]="masterKey"
            (cdkCopyToClipboardCopied)="showCopyNotification('Master password was copied to clipboard.')">
            <mat-icon>content_copy</mat-icon>
        </button>
    </div>
</div>