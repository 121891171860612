<app-alert></app-alert>

<div class="payment-page">
    <h1 class="mat-h1" class="payment-page__header">Payment</h1>
    <table mat-table [dataSource]="subscriptionInfo" class="mat-elevation-z4">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef>Plan</th>
          <td mat-cell *matCellDef="let element"> <span class="plan-name">{{element.plan}}</span> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="charge-date">
          <th mat-header-cell *matHeaderCellDef>Charge date</th>
          <td mat-cell *matCellDef="let element"> <strong>{{element.chargeDate}}</strong> </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> <strong>${{element.amount}}</strong> </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef> Cost per users </th>
          <td mat-cell *matCellDef="let element"> {{element.users}} </td>
        </ng-container>

        <ng-container matColumnDef="trial">
            <th mat-header-cell *matHeaderCellDef> Trial period </th>
            <td mat-cell *matCellDef="let element"> {{element.trial}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <p class="mat-body-1 note">
        <strong>Important:</strong> You can cancel anytime before {{endOfTrialDate}} to avoid charges.
    </p>

    <div *ngIf="paymentElementForm" [formGroup]="paymentElementForm" class="billing-form">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>ZIP Code</mat-label>
            <input matInput formControlName="zipcode" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" />
        </mat-form-field>
        <div *ngIf="elementsOptions?.clientSecret as clientSecret"
            class="full-width">
            <ngx-stripe-payment [clientSecret]="clientSecret">
            </ngx-stripe-payment>
        </div>
        <button mat-flat-button color="accent"
            class="full-width"
            (click)="pay()">
            PAY
        </button>
    </div>
</div>

