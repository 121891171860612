<div class="db-table-header" #focus>
    <div class="db-table-title">
        <h2 class="mat-h2 table-name">{{ displayName }}</h2>

        <mat-form-field appearance="outline" class="table-switcher">
            <mat-label>Table</mat-label>
            <input type="text"
                placeholder="Select a table"
                matInput
                [value]="name"
                [matAutocomplete]="auto"
                (focus)="onInputFocus()"
                (input)="onInput($event.target.value)">
            <mat-autocomplete autoActiveFirstOption
                #auto="matAutocomplete">
                <mat-option *ngFor="let table of filteredTables"
                    class="table-switcher-option"
                    [value]="table.table">
                    <a class="table-switcher-link"
                        routerLink="/dashboard/{{connectionID}}/{{table.table}}"
                        [queryParams]="{page_index: 0, page_size: 30}">
                        {{table.normalizedTableName}}
                </a>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <button mat-icon-button (click)="loadRowsPage()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <div *ngIf="selection.selected.length; else generalActions" class="db-table-bulk-actions">
        <div class="actions">
            <ng-container *ngIf="tableData.tableBulkActions && tableData.tableBulkActions.length">
                <button type="button" mat-button *ngFor="let action of tableData.tableBulkActions"
                (click)="handleActions(action)">
                    {{action.title}}
                </button>
            </ng-container>
            <button mat-button
                (click)="handleActions({title: 'Delete rows', type: 'multiple',  require_confirmation: true})">
                Delete
            </button>
        </div>
    </div>
    <ng-template #generalActions>
        <div class="db-table-actions">
            <form #searchForm="ngForm"
                angulartics2On="submit"
                angularticsAction="Dashboard: search is applied"
                (ngSubmit)="handleSearch()">
                <mat-form-field appearance="fill" class="search-input">
                    <input matInput name="search"
                        placeholder="Search"
                        [(ngModel)]="searchString">
                    <button type="button" mat-icon-button matSuffix *ngIf="searchString"
                        (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </form>
            <div class="actions">
                <button mat-button type="button"
                    matTooltip="AI assistant"
                    angulartics2On="click"
                    angularticsAction="Dashboard: AI is clicked"
                    (click)="handleViewAIpanel()">
                    <mat-icon svgIcon="ai_rocket" class="ai-icon"></mat-icon>
                    AI insights
                </button>

                <a mat-button *ngIf="tableData && tableData.permissions && tableData.permissions.add && (!tableData.isEmptyTable || searchString || getFiltersCount(activeFilters))"
                    routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
                    data-testid="table-add-record-link"
                    angulartics2On="click"
                    angularticsAction="Dashboard: add row is clicked"
                    (click)="stashFilters()">
                    <mat-icon>add</mat-icon>
                    Add row
                </a>

                <button mat-button type="button" [ngClass]="{'action_active': getFiltersCount(activeFilters)}"
                    angulartics2On="click"
                    angularticsAction="Dashboard: filters is clicked"
                    (click)="handleOpenFilters()">
                    <mat-icon>filter_list</mat-icon>
                    Filter
                </button>
                <div *ngIf="tableData" class="db-table-manage-columns-button">
                    <button *ngIf="tableData.displayedColumns && tableData.columns" mat-button [matMenuTriggerFor]="menu"
                        angulartics2On="click"
                        angularticsAction="Dashboard: columns multiselect is clicked">
                        <mat-icon fontSet="material-icons-outlined">view_week</mat-icon>
                        Columns ({{ tableData.displayedDataColumns.length   }}
                            /
                            {{ tableData.columns.length }})
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            role="menuitemcheckbox"
                            *ngFor="let column of tableData.columns"
                            (click)="$event.stopPropagation();">
                            <mat-checkbox
                                [disabled]="tableData.displayedDataColumns.length === 1 && column.selected"
                                (change)="tableData.changleColumnList(connectionID, name)"
                                [(ngModel)]="column.selected">
                                {{column.normalizedTitle}}
                            </mat-checkbox>
                        </button>
                    </mat-menu>
                </div>
                <button mat-icon-button type="button"
                    *ngIf="accessLevel === 'edit'"
                    matTooltip="Emport / Export"
                    [matMenuTriggerFor]="transferDataMenu"
                    angulartics2On="click"
                    angularticsAction="Dashboard: settings dropdown is clicked">
                    <mat-icon>swap_vert</mat-icon>
                </button>
                <mat-menu #transferDataMenu="matMenu">
                    <button mat-menu-item *ngIf="tableData && tableData.isImportAllowed" type="button"
                        angulartics2On="click"
                        angularticsAction="Dashboard: import is clicked"
                        (click)="handleOpenImportDialog()">
                        Import
                    </button>
                    <button mat-menu-item *ngIf="tableData && tableData.isExportAllowed" type="button"
                        angulartics2On="click"
                        angularticsAction="Dashboard: export is clicked"
                        (click)="handleOpenExportDialog()">
                        Export
                    </button>
                </mat-menu>
                <button mat-icon-button type="button"
                    *ngIf="accessLevel === 'edit'"
                    [matMenuTriggerFor]="settingsMenu"
                    angulartics2On="click"
                    angularticsAction="Dashboard: settings dropdown is clicked">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #settingsMenu="matMenu">
                    <a mat-menu-item *ngIf="accessLevel === 'edit'"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/settings"
                        angulartics2On="click"
                        angularticsAction="Dashboard: settings is clicked">
                        Settings
                    </a>
                    <a mat-menu-item *ngIf="tableData"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/widgets"
                        [ngClass]="{'action_active': tableData.widgetsCount}"
                        angulartics2On="click"
                        angularticsAction="Dashboard: widgets is clicked">
                        Widgets
                    </a>
                    <a mat-menu-item *ngIf="tableData" [ngClass]="{ 'action-button_disabled': !tableData.keyAttributes?.length }"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/actions"
                        [disabled]="!tableData.keyAttributes?.length"
                        angulartics2On="click"
                        angularticsAction="Dashboard: actions is clicked">
                        Actions
                        <br />
                        <span *ngIf="!tableData.keyAttributes?.length" class="mat-small">
                            Primary keys are required.
                        </span>
                    </a>
                </mat-menu>
            </div>
        </div>
    </ng-template>
</div>

<div *ngIf="getFiltersCount(activeFilters) !== 0" class="active-filters">
    <mat-chip-row *ngFor="let activeFilter of activeFilters | keyvalue"
        (removed)="removeFilter.emit(activeFilter.key)">
        {{ getFilter(activeFilter) }}
        <button matChipRemove>
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-chip-row>
</div>

<div *ngIf="tableData && tableData.loading$ | async" class="skeleton mat-elevation-z4">
    <app-placeholder-table-data></app-placeholder-table-data>
</div>

<div [ngClass]="{hidden: !tableData || tableData.loading$ | async}" class="mat-elevation-z4 table-surface">
    <div class="table-box">
        <mat-table matSort [dataSource]="tableData" NgMatTableQueryReflector
            class="db-table"
            [ngClass]="tableData.actionsColumnWidth === '0' ? 'db-table_withoutActions' : 'db-table_withActions'"
            [style.--colCount]="tableData.displayedDataColumns?.length"
            [style.--lastColumnWidth]="tableData.actionsColumnWidth">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox data-testid="table-select-all-checkbox"
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [disabled]="tableData.isEmptyTable"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; let i = index" class="db-table-cell-checkbox">
                  <mat-checkbox class="db-table-checkbox"
                        attr.data-testid="table-select-record-{{i}}-checkbox"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="column" *ngFor="let column of tableData.displayedDataColumns">
                <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isSortable(column)"> {{ tableData.dataNormalizedColumns[column] }} </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" [attr.data-label]="tableData.dataNormalizedColumns[column]" class="db-table-cell" data-hj-suppress>
                    <div class="table-cell-content">
                        <span *ngIf="isForeignKey(column); else contentCell" class="field-value">
                            <a routerLink="/dashboard/{{connectionID}}/{{tableData.foreignKeys[column].referenced_table_name}}/entry"
                                class="foreign-key-link"
                                [queryParams]="getForeignKeyQueryParams(tableData.foreignKeys[column], element[column])">
                                {{ getCellValue(tableData.foreignKeys[column], element[column]) }}
                            </a>
                            <button type="button" mat-icon-button
                                class="field-value-copy-button"
                                matTooltip="Copy"
                                [cdkCopyToClipboard]="getCellValue(tableData.foreignKeys[column], element[column])"
                                (click)="$event.stopPropagation()"
                                (cdkCopyToClipboardCopied)="showCopyNotification('Field value was copied to clipboard.')">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </span>
                        <ng-template #contentCell>
                            <div *ngIf="isWidget(column); else simpleValue" class="field-value">
                                <img *ngIf="tableData.widgets[column].widget_type === 'Image' && element[column]"
                                    [src]="element[column]"
                                    [style.--height.px]="tableData.widgets[column].widget_params.height"
                                    class="field-image">

                                <span>{{ getWidgetValue(column, element[column]) }}</span>

                                <button type="button" mat-icon-button
                                    class="field-value-copy-button"
                                    matTooltip="Copy"
                                    [cdkCopyToClipboard]="getWidgetValue(column, element[column])"
                                    (click)="$event.stopPropagation()"
                                    (cdkCopyToClipboardCopied)="showCopyNotification('Field value was copied to clipboard.')">
                                    <mat-icon>content_copy</mat-icon>
                                </button>
                            </div>
                            <ng-template #simpleValue>
                                <span *ngIf="tableData.keyAttributes[0]?.column_name === column; else nonPrimaryKey"
                                    attr.data-testid="table-primary-key-{{i}}-cell"
                                    class="field-value">
                                    {{element[column] || '—'}}
                                    <button type="button" mat-icon-button
                                        class="field-value-copy-button"
                                        matTooltip="Copy"
                                        [cdkCopyToClipboard]="element[column]"
                                        (click)="$event.stopPropagation()"
                                        (cdkCopyToClipboardCopied)="showCopyNotification('Field value was copied to clipboard.')">
                                        <mat-icon>content_copy</mat-icon>
                                    </button>
                                </span>
                                <ng-template #nonPrimaryKey>
                                    <span class="field-value">
                                        {{element[column] || '—'}}
                                        <button type="button" mat-icon-button
                                            class="field-value-copy-button"
                                            matTooltip="Copy"
                                            [cdkCopyToClipboard]="element[column]"
                                            (click)="$event.stopPropagation()"
                                            (cdkCopyToClipboardCopied)="showCopyNotification('Field value was copied to clipboard.')">
                                            <mat-icon>content_copy</mat-icon>
                                        </button>
                                    </span>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions" [stickyEnd]="true">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>

                <mat-cell *matCellDef="let element; let i = index" class="db-table-cell-actions">
                    <ng-container *ngIf="tableData.tableActions && tableData.tableActions.length">
                        <button type="button" mat-icon-button *ngFor="let action of tableData.tableActions"
                            [matTooltip]="action.title"
                            (click)="handleAction($event, action, element)">
                            <mat-icon fontSet="material-icons-outlined">
                                {{action.icon}}
                            </mat-icon>
                        </button>
                    </ng-container>
                    <a mat-icon-button *ngIf="tableData.permissions.edit"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
                        [queryParams]="tableData.getQueryParams(element)"
                        attr.data-testid="table-edit-record-{{i}}-link"
                        attr.data-test-primary-key-name="{{tableData.keyAttributes[0]?.column_name}}"
                        attr.data-test-primary-key-value="{{element[tableData.keyAttributes[0]?.column_name]}}"
                        angulartics2On="click"
                        angularticsAction="Dashboard: edit row is clicked"
                        matTooltip="Edit row"
                        (click)="stashUrlParams()">
                        <mat-icon fontSet="material-icons-outlined">create</mat-icon>
                    </a>
                    <a mat-icon-button *ngIf="tableData.permissions.add"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
                        [queryParams]="tableData.getQueryParams(element, 'dub')"
                        attr.data-testid="table-edit-record-{{i}}-link"
                        attr.data-test-primary-key-name="{{tableData.keyAttributes[0]?.column_name}}"
                        attr.data-test-primary-key-value="{{element[tableData.keyAttributes[0]?.column_name]}}"
                        angulartics2On="click"
                        angularticsAction="Dashboard: duplicate row is clicked"
                        matTooltip="Duplicate row"
                        (click)="stashFilters()">
                        <mat-icon fontSet="material-icons-outlined">difference</mat-icon>
                    </a>
                    <button type="button" mat-icon-button *ngIf="tableData.permissions.delete"
                        attr.data-testid="table-delete-record-{{i}}-button"
                        angulartics2On="click"
                        angularticsAction="Dashboard: delete row is clicked"
                        matTooltip="Delete row"
                        (click)="handleDeleteRow($event, element)">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="tableData.actionsColumnWidth === '0' ? tableData.displayedDataColumns : tableData.displayedColumns"
                class="db-table-header-row">
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: tableData.actionsColumnWidth === '0' ? tableData.displayedDataColumns : tableData.displayedColumns;"
                class="db-table-row"
                [ngClass]="{'db-table-row_selected': isRowSelected(tableData.getQueryParams(row))}"
                (click)="handleViewRow({record: row, primaryKeys: tableData.getQueryParams(row)})">
            </mat-row>
        </mat-table>
    </div>

    <div *ngIf="tableData.isEmptyTable && !getFiltersCount(activeFilters) && searchString" class="empty-table">
        <p class="mat-body-1">No field matches <span style="font-family: monospace;">"{{staticSearchString}}"</span>.</p>
        <p class="mat-body-1">If you want to find <u>substring</u>, please, use <strong>Filter</strong>.</p>
    </div>

    <div *ngIf="tableData.isEmptyTable && getFiltersCount(activeFilters) && !searchString" class="empty-table">
        <p class="mat-body-1">No field matches this filter.</p>
    </div>

    <div *ngIf="tableData.isEmptyTable && !(getFiltersCount(activeFilters) || searchString)" class="empty-table">
        <a mat-button color="accent"
            routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
            angulartics2On="click"
            angularticsAction="Dashboard: add row is clicked"
            data-testid="table-add-record-in-empty-table-link">
            <mat-icon>add</mat-icon>
            Add row
        </a>
    </div>

    <mat-paginator
        [pageSize]="30"
        [pageSizeOptions]="[10, 30, 100, 300]"
        [showFirstLastButtons]="!tableData.largeDataset">
    </mat-paginator>
    <!--(page)="scrollUp()"}-->
</div>
