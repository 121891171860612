<mat-form-field class="form-field" appearance="outline">
    <mat-label>{{normalizedLabel}}</mat-label>
    <textarea matInput name="{{label}}-{{key}}"
        class="form-textarea" [rows]="rowsCount"
        attr.data-testid="record-{{label}}-long-text"
        [required]="required" [disabled]="disabled" [readonly]="readonly"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
    </textarea>
</mat-form-field>
<textarea [ngModel]="value" (ngModelChange)="value = $event" [rows]="rowsCount"></textarea>
